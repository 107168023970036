import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import { Layout } from '../components';
import { ProjectTypeTemplate } from '../components/ProjectType';

export const FeasibilityPageTemplate = ({ data }) => {
    const {
        markdownRemark: { frontmatter },
        allMarkdownRemark: { edges: projects },
    } = data;
    return (
        <Layout title={frontmatter.title} description={frontmatter.description}>
            <ProjectTypeTemplate description={frontmatter.description} projects={projects} />
        </Layout>
    );
};

FeasibilityPageTemplate.propTypes = {
    data: PropTypes.shape({
        markdownRemark: PropTypes.shape({
            frontmatter: PropTypes.shape({
                title: PropTypes.string.isRequired,
                description: PropTypes.string.isRequired,
                heroImages: PropTypes.arrayOf(
                    PropTypes.shape({
                        image: PropTypes.string,
                        title: PropTypes.string,
                    })
                ),
                projectTypes: PropTypes.arrayOf(
                    PropTypes.shape({
                        image: PropTypes.string,
                        title: PropTypes.string,
                    })
                ),
            }),
        }),
        allMarkdownRemark: PropTypes.shape({
            edges: PropTypes.arrayOf(
                PropTypes.shape({
                    node: PropTypes.shape({
                        frontmatter: PropTypes.shape({
                            title: PropTypes.string.isRequired,
                            description: PropTypes.string.isRequired,
                            heroImages: PropTypes.arrayOf(
                                PropTypes.shape({
                                    image: PropTypes.string,
                                    title: PropTypes.string,
                                })
                            ),
                        }),
                    }),
                })
            ),
        }),
    }),
};

export const query = graphql`
    query FeasibilityPageTemplateQuery {
        markdownRemark(frontmatter: { templateKey: { eq: "feasibility-page" } }) {
            frontmatter {
                title
                description
            }
        }
        allMarkdownRemark(
            sort: { order: ASC, fields: [frontmatter___date] }
            filter: { frontmatter: { templateKey: { eq: "project-page" }, tags: { in: ["feasibility"] } } }
        ) {
            edges {
                node {
                    excerpt(pruneLength: 400)
                    id
                    fields {
                        slug
                    }
                    frontmatter {
                        title
                        about
                        featuredImage
                        category
                        location
                        mapLocation
                        cost
                        architect
                        client {
                            name
                            link
                        }
                    }
                }
            }
        }
    }
`;

export default FeasibilityPageTemplate;
